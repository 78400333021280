.avatar {
  display: inline-block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #eead2d;
  overflow: hidden;
  position: absolute;
  top: -20px;
  right: -45px;
}

.avatar img {
  width: 100%;
  height: 100%;
}