
#carousel{
width: 100%;
height: 60vh;
}

#carousel img, #carousel video{

  width: 100%;
  height: 60vh;
  margin: 0 auto;
  object-fit: fill;

}

@media (max-width: 450px) {

  #carousel, #carousel img{
    height: 20vh;
    }
   
}