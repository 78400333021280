/* Footer */
#main-footer {
  background: #333;
  color: #fff;
}

#main-footer img {
  width: 150px;
}

#main-footer a {
  color: #fff;
}

#main-footer .footer-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  max-width: 1110px;
  margin: 0 auto;
  align-items: baseline;
}

#main-footer .footer-container>* {
  padding: 1rem;
  /* my ajustment */
}

#main-footer .footer-container>*:last-child {
  background: #444;
  grid-column: 1 /span 4;
  text-align: center;
  padding: 0.5rem;
  font-size: 0.75rem;
  margin-bottom: 35px;
}

#main-footer .footer-container input[type=email] {
  width: 90%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

#main-footer .footer-container input[type=submit] {
  width: 90%;
}

.list li {
  padding: 0.5rem 0;
  border-bottom: #555 dotted 1px;
  width: 90%;
  list-style: none;
}
#main-footer h3{
  margin-bottom: 0.55rem;
  line-height: 1.3;
  margin: 10px 0 0 15px;
  color: #fff;
  font-weight: bold;
}

@media(max-width:600px) {

  /* Stack Grid Itens */
  #showcase .showcase-container,
  #home-articles .articles-container,
  #main-footer .footer-container {
    grid-template-columns: 1fr;
  }

  #main-footer .footer-container>*:last-child {
    grid-column: 1;
    /* if you have spaning some quantity of columns, you can't make it grid-template-columns: 1fr, it's goint to overwrite it. It's going to adapt to whatever amount of columns you are spanning */
  }

  #main-footer .footer-container>*:first-child,
  #main-footer .footer-container>*:nth-child(2) {
    border-bottom: #444 dotted 1px;
    padding-bottom: 1rem;
  }


  .page-container {
    grid-template-columns: 1fr;
    text-align: center;
    justify-content: initial;
    justify-items: end;
    align-items: baseline;
  }

  .page-container>*:first-child {
    grid-row: 1;
  }

  .l-heading {
    font-size: 2rem;
  }
}

#main-footer .social {
  /* justify-self: center; */
}

#main-footer .social i {
    margin-right: 0.5rem;
}
#main-footer i:hover {
  color: #c21111 !important;
  margin-right: 0.5rem;
}
#main-footer a:hover {
  color: #c21111 !important;
}

/* Whatsapp */

.whatsapp-icon {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000; /* Ensure it's above other content */
}

.whatsapp-icon img {
  width: 50px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
}

.termos{
  width: 100%;
  background: black;
  padding: 2px;
  color: white;
  text-align: center;
  text-decoration: none;
}
.termos :hover{
  cursor: pointer;
}
