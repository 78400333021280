.file-input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  padding: 2px 10px;
  background-color: #696969;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-label:hover {
  background-color: #0056b3;
}

.file-name {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}


#concordo-termos-filiacao .checkbox-wrapper {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#concordo-termos-filiacao .checkbox-input {
  display: none;
}

#concordo-termos-filiacao .checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

#concordo-termos-filiacao .checkbox-custom {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #007bff;
  border-radius: 3px;
  margin-right: 10px;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

#concordo-termos-filiacao .checkbox-input:checked + .checkbox-label .checkbox-custom {
  background-color: #007bff;
  border-color: #007bff;
}

#concordo-termos-filiacao .checkbox-input:checked + .checkbox-label .checkbox-custom::after {
  content: '';
  position: absolute;
  top: -1px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.badge-doc-reprovada, .badge-doc-generico {
  display: inline-block;
  padding: 3px 5px;
  font-size: 100%;
  font-weight: bold;
  background: #dc3545;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-doc-generico{
  background: #ffc107 !important;
}


/* Radio Button */

.form-container {
  padding: 20px;
  max-width: 400px;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.radio-label {
  display: block;
  margin: 10px 0;
  font-size: 18px;
}

.radio-input {
  margin-right: 10px;
}

.selected-option {
  margin-top: 20px;
  font-size: 20px;
  color: #333;
}